.table-users {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.table-users col:hover {
  background-color: #efefef;
}

.table-users col.sorted-col {
  background-color: #eaeaea;
}

.table-users th {
  border-bottom: 2px solid black;
}

.table-header-sortable {
  cursor: pointer;
}

.table-users-row {
  border-bottom: 1px solid #bababa;
  height: 30px;
}

.table-users-row:hover {
  background-color: #eaeaea;
}

.table-paging-info {
  margin-left: 5px;
  margin-right: 5px;
}
