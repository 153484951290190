.table-info {
  width: 500px;
}

.table-info-col-key {
  width: 40%;
}

.table-info-col-value {
  width: 60%;
}
