.main-container {
  padding: 10px;
}

.login-form {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-form-error {
  color: red;
}

.navigation-container {
  display: flex;
  width: 100%;
  margin-bottom: 1px;
}

.navigation-item {
  width: 100px;
  flex: 0 1 auto;
  border: solid black;
  border-radius: 10%;
  text-align: center;
  text-decoration: none;
  color: black;
  margin-right: 5px;
}

.navigation-item:hover {
  background-color: #e3e4f4;
}

.date-picker-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
}

.link {
  cursor: pointer;
}

.filters {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

.filter-group {
  display: flex;
  flex-direction: column;
  margin: 5px;
}

.stats-main-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-right: 40px;
}

.charts-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

.chart-container {
  display: flex;
  flex-direction: column;
}

.full-width {
  width: 100%;
  height: 400px;
}

.chart-title {
  margin-top: 50px;
}

.button__export {
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 5px;
}
